import React from "react";
import "./awards.scss";
import { graphql, StaticQuery } from "gatsby";

const Awards = ({ data }) => {
	if (!data) return null;
	const { partners: { edges = {} } = {} } = data;

	const collection = edges
		.map(item => item.node.childMarkdownRemark.frontmatter)
		.sort((a, b) => a.order - b.order);

	return (
		<section className="full-width awards">
			<div className="grid-container">
				<div className="row">
					<div className="col-xs-12 col-md-offset-2 col-md-6 col-sm-offset-1 col-sm-10">
						<h2>Награды</h2>
					</div>
					{collection &&
						collection.map(({ title, content, image, order }) => (
							<div
								className="col-xs-12 col-md-offset-2 col-md-6 col-sm-offset-1 col-sm-10"
								key={order}
							>
								<div className="awards__award">
									<img
										alt={title}
										className="awards__award__image"
										src={image}
									/>
									<div className="awards__award__content">
										<h4>{title}</h4>
										<p>{content}</p>
									</div>
								</div>
							</div>
						))}
				</div>
			</div>
		</section>
	);
};

export default props => (
	<StaticQuery
		query={graphql`
			query {
				partners: allFile(filter: { relativeDirectory: { eq: "awards" } }) {
					edges {
						node {
							childMarkdownRemark {
								frontmatter {
									title
									content
									image
									order
								}
							}
						}
					}
				}
			}
		`}
		render={data => <Awards data={data} {...props} />}
	/>
);
